import React from "react"
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline"
import * as containerStyles from "./Banner.module.css"

const Banner = () => {
  return (
    <div className="bg-primary rounded-lg mt-8">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-primaryLighter">
              <SpeakerphoneIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 font-medium text-white">
              <span className="inline">
                Contact us at{" "}
                <a
                  className={containerStyles.mailLink}
                  href="mailto:sales@ergsense.com"
                >
                  sales@ergsense.com
                </a>{" "}
                for pricing information.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
