import React, { useState, Fragment } from "react"
import { graphql } from "gatsby"
import { Tab } from "@headlessui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as containerStyles from "./product.module.css"
import Seo from "../components/Seo/Seo"
import Banner from "../components/Banner/Banner"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const ProductTemplate = data => {
  const product2 = data.data.wpSimpleProduct

  return (
    <>
      <Seo title={product2.name} />
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-2">
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            {/* Image gallery */}
            <div>
              <GatsbyImage
                image={getImage(product2.image.localFile)}
                alt={product2.image.alt}
                height={450}
                className="w-full object-center object-cover sm:rounded-lg"
              />
            </div>
            {/* Product info */}
            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
                {product2.name}
              </h1>

              <div className="mt-3">
                <h2 className="sr-only">Product information</h2>
                <p className="text-3xl text-gray-900">{product2.price}</p>
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>

                <div
                  className={`text-base text-gray-700 space-y-6 ${containerStyles.productDescriptionText}`}
                  dangerouslySetInnerHTML={{ __html: product2.description }}
                />
              </div>

              <Banner />
            </div>
          </div>
        </div>
        <div className="bg-white">
          <section
            aria-labelledby="features-heading"
            className="max-w-7xl mx-auto py-32 sm:px-2 lg:px-8 lg:pt-8 border-t border-b border-gray-200 mt-12"
          >
            <div className="max-w-2xl mx-auto px-4 lg:px-0 lg:max-w-none">
              <div className="max-w-3xl">
                <h2
                  id="features-heading"
                  className="text-3xl font-extrabold tracking-tight text-secondary sm:text-4xl"
                >
                  {product2.productFeatures.featuresHeadingText}
                </h2>
                <p className="mt-4 text-gray-500">
                  {product2.productFeatures.featuresSubheadingText}
                </p>
              </div>

              <Tab.Group as="div" className="mt-4">
                <div className="-mx-4 flex overflow-x-auto sm:mx-0">
                  <div className="flex-auto px-4 border-b border-gray-200 sm:px-0">
                    <Tab.List className="-mb-px flex space-x-10">
                      {product2.productFeatures.featureTabs.map(tab => (
                        <Tab
                          key={tab.tabTitle}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "border-primaryDarker text-primaryDarker20"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "whitespace-nowrap py-6 border-b-2 font-medium text-sm"
                            )
                          }
                        >
                          {tab.tabTitle}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                </div>

                <Tab.Panels as={Fragment}>
                  {product2.productFeatures.featureTabs.map(tab => (
                    <Tab.Panel
                      key={tab.tabTitle}
                      className="max-w-3xl space-y-16 pt-10 lg:pt-8"
                    >
                      <div
                        className={`mt-2 p-4 text-sm text-gray-500 ${containerStyles.productDescriptionText}`}
                        dangerouslySetInnerHTML={{
                          __html: tab.tabContent,
                        }}
                      ></div>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpSimpleProduct(id: { eq: $id }) {
      id
      name
      description
      databaseId
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 450, quality: 100)
          }
        }
      }
      productFeatures {
        featuresHeadingText
        featuresSubheadingText
        featureTabs {
          tabTitle
          tabContent
        }
      }
    }
  }
`

export default ProductTemplate
